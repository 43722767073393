<template>
  <WeContainer card="">
    <div class="card">
      <div class="card-header pt-4 pb-4 custom-header-bg">
        <div class="row align-items-center">
          <div class="col">
            <h5 class="custom-card-title">
              <i class="fas fa-comments"></i>
              <span class="text-uppercase">MÜŞTERİ YORUMLARI</span>
            </h5>
          </div>
          <div class="col-auto">
            <router-link to="comments/trash" class="btn btn-outline-danger">
              <i class="fas fa-trash"></i> Çöp Kutusu
            </router-link>
          </div>
        </div>
      </div>
      <div class="card-body position-relative" style="min-height: 200px">
        <WeTable
          v-bind:index="false"
          v-bind:data="comment.list"
          v-bind:columns="columns"
          v-bind:actions="tableActions"
          v-on:on-action="onAction"
          v-on:on-switch="onSwitch"
        ></WeTable>
      </div>
      <WeModal
        v-if="showModal"
        v-on:close="showModal = false"
        title="Yorum Detayı"
      >
        <div slot="body" style="min-height: 250px">
          <div v-if="commentData">
            <table class="table table-bordered">
              <tr scope="row">
                <th width="30%">Ad Soyad</th>
                <td>{{ commentData.member }}</td>
              </tr>
              <tr scope="row">
                <th width="30%">E-Posta Adresi</th>
                <td>{{ commentData.email }}</td>
              </tr>
              <tr scope="row">
                <th width="30%">Tarih</th>
                <td>{{ getCommentDate }}</td>
              </tr>
              <tr scope="row">
                <th width="30%">Ürün</th>
                <td>
                  {{ commentData.product_name }}
                </td>
              </tr>
              <tr scope="row">
                <th width="30%">Puan</th>
                <td>
                  <star-rating
                    v-bind:rating="parseInt(commentData.score)"
                    v-bind:read-only="true"
                    v-bind:increment="0.01"
                    v-bind:star-size="20"
                  ></star-rating>
                </td>
              </tr>
              <!-- <tr scope="row">
                <th width="30%">Yorum Başlığı</th>
                <td>
                  {{ commentData.title }}
                </td>
              </tr> -->
              <tr scope="row">
                <th width="30%">Yorum</th>
                <td>
                  <div
                    class="custom-scrollbar"
                    style="max-height: 250px; overflow: auto"
                  >
                    {{ commentData.comment }}
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <WeLoading v-else />
        </div>
      </WeModal>
    </div>
  </WeContainer>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "List",
  data() {
    return {
      ready: false,
      showModal: false,
      commentData: null,
      tableActions: [
        {
          icon: "fas fa-eye",
          class: "btn-outline-indigo ml-2",
          action: "show",
          tooltip: "Görüntüle",
        },
        {
          icon: "fas fa-trash",
          class: "btn-outline-danger ml-2",
          action: "remove",
        },
      ],
      columns: [
        { name: "date", th: "Tarih", type: "datetime", width: "15%" },
        { name: "member", th: "Müşteri Adı Soyadı", type: "string" },
        { name: "email", th: "E-Posta Adresi", type: "string" },
        { name: "product_name", th: "Ürün Adı", type: "string" },
        // { name: "title", th: "Yorum Başlığı", type: "string" },
        { name: "score", th: "Puan", type: "star" },
        { name: "is_active", th: "Onay Durumu", type: "boolean" },
      ],
      filter: {
        full_name: null,
        product_name: null,
        email: null,
        date: null,
      },
    };
  },
  methods: {
    ...mapActions("comment", ["getList", "delete", "isActive"]),
    onAction(data) {
      if (data.key == "remove") {
        this.onDelete(data.row, data.index);
      }
      if (data.key == "show") {
        this.onShow(data.row);
      }
    },
    onDelete(row) {
      let message = "Yorumu silmek istediğinize emin misiniz ?";

      this.$swal({
        title: message,
        html: `Bu işlemi <b class="text-danger">Çöp Kutusu</b> bölümünden geri alabilirsiniz.`,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.delete({
            id: row.id,
            onSuccess: (result) => {
              if (result && result.data && result.data.status !== "error") {
                this.$toast.success("Yorum Başarıyla Silindi");
                this.removeRow(row.id);
              } else {
                this.$swal({
                  title: "Yorum Silinemedi",
                  text: result.data.message,
                  icon: "error",
                  confirmButtonText: "Tamam",
                });
              }
            },
          });
        }
      });
    },
    removeRow(id) {
      const index = this.comment.list.findIndex((item) => item.id === id);
      if (index >= 0) {
        this.comment.list.splice(index, 1);
      }
    },
    onShow(data) {
      this.showModal = true;
      this.commentData = null;
      setTimeout(() => {
        this.commentData = data;
      }, 1000);
    },
    onSwitch(data) {
      this.isActive({
        id: data.id,
        is_active: data.is_active,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$toast.success("Onay Durumu Güncellendi");
          }
        },
      });
    },
  },
  computed: {
    ...mapState(["comment", "shared"]),
    getCommentDate() {
      return new Date(this.commentData.date).toLocaleString();
    },
  },
  mounted() {
    this.getList({
      onSuccess: () => {
        this.ready = true;
      },
    });
  },
};
</script>
